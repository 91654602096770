@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url("/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url("/fonts/Roboto-Bold.ttf");
}
* {
  box-sizing: border-box;
}
div,
span {
  font-family: 'Roboto';
}
#root {
  width: 100vw;
  height: 100vh;
}
.container {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #d6d6d6;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  align-items: center;
  justify-content: center;
  padding: 1vh;
}
.container__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 90vh;
}
.top-panel {
  display: flex;
  align-items: center;
  width: 33vh;
  margin: 1vh 2vh 1vh 0;
}
.button {
  display: flex;
  background-color: #bbb;
  border: 0.1vh solid #503e3e;
  align-items: center;
  cursor: pointer;
  padding: 2vh 1vh;
  height: 3vh;
  font-size: 2vh;
  border-radius: 0.75vh;
  width: 33vh;
}
.button__icon {
  background: url("/restart.svg");
  background-size: contain;
/* filter: brightness(0) invert(1); */
  width: 3vh;
  height: 3vh;
}
.button__text {
  text-transform: uppercase;
/* font-weight: 700; */
/* font-size: 1.25em;
  height: 1.25em;
  line-height: 1.35em; */
  color: #503e3e;
  padding: 0 0.3vh 0 1vh;
  flex-grow: 1;
  text-align: center;
}
.game-over {
  position: absolute;
  width: 87.2vh;
  height: 87.2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.game-over__container {
  margin: 0 25vh;
  padding: 4vh 2vh;
  background-color: #d6d6d6;
  z-index: 1;
  border-radius: 0.75vh;
}
.game-over__text {
  text-align: center;
  color: #503e3e;
  font-size: 3.5vh;
  margin: 1vh 0;
}
.pre-score {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  vertical-align: bottom;
  margin-right: 1.5vh;
}
.pre-score__text {
  display: inline-block;
  text-align: right;
  color: #503e3e;
  background-color: #bbb;
  font-size: 3vh;
  line-height: 3vh;
  padding: 0.65vh 1vh;
  border-radius: 0.2em;
}
.score {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-weight: 700;
  height: 6vh;
  vertical-align: bottom;
  margin-right: 0.75vh;
}
.score__number {
  display: inline-block;
  text-align: right;
  color: #503e3e;
  font-size: 6vh;
  line-height: 6vh;
}
.score__number--anim {
  animation: score-update 0.7s linear 1 both running;
}
.score__label {
  display: inline-block;
  color: #503e3e;
  opacity: 0.7;
  margin-left: 0.2em;
  font-size: 3vh;
  line-height: 4vh;
}
.game-area {
  display: flex;
  flex-direction: row;
  border: 0.4vh solid #503e3e;
  background-color: #503e3e;
  width: 90vh;
  height: 90vh;
  padding: 1vh;
  border-radius: 0.75vh;
}
.side-panel {
  display: flex;
  flex-direction: column;
  border: 0.4vh solid #503e3e;
  background-color: #503e3e;
  width: 33vh;
  border-radius: 0.75vh;
  margin: 1vh 2vh 1vh 0;
  overflow: hidden;
}
.side-panel--game-size__container {
  display: flex;
  flex-direction: row;
}
.side-panel__header {
  height: 2.6vh;
  color: #fff;
  font-size: 2vh;
  margin: 1vh 0;
  text-align: center;
  text-transform: uppercase;
}
.high-score {
  display: flex;
  flex-direction: row;
  color: #000;
  font-size: 2.5vh;
  padding: 0.5vh 1vh;
  overflow: hidden;
}
.high-score--odd {
  background-color: #ccc;
}
.high-score--even {
  background-color: #ddd;
}
.high-score__place {
  width: 6.5vh;
}
.high-score__date {
  font-size: 1.75vh;
  line-height: 3vh;
}
.high-score__score {
  flex-grow: 1;
  text-align: right;
}
.game-size {
  color: #000;
  font-size: 2.5vh;
  padding: 0.5vh 0;
  text-align: center;
  flex-grow: 1;
  cursor: pointer;
}
.game-size--odd {
  background-color: #ccc;
}
.game-size--even {
  background-color: #ddd;
}
.game-size--selected {
  color: #ddd;
  font-weight: bold;
}
.info-area {
  display: flex;
  flex-direction: row;
  background-color: #503e3e;
  width: 75vh;
  height: 5vh;
  padding: 1vh;
  border-radius: 0.75vh;
  margin-top: 1vh;
  color: #aaa;
  font-weight: 700;
  font-size: 4vh;
}
.column {
  display: flex;
  flex-direction: column-reverse;
  width: 0.1em;
  flex-grow: 1;
  justify-content: flex-start;
}
.ball {
  display: block;
  box-sizing: border-box;
  padding: 47.5%;
  margin: 2.5%;
  border-radius: 50%;
}
.ball--highlight {
  border: 0.25em solid #fff;
  padding: calc(47.5% - 0.25em);
  cursor: pointer;
}
.ball--red {
  background: radial-gradient(farthest-corner at 150% 150%, #fff 5%, #f00);
}
.ball--blue {
  background: radial-gradient(farthest-corner at 150% 150%, #fff 5%, #00f);
}
.ball--green {
  background: radial-gradient(farthest-corner at 150% 150%, #fff 5%, #008000);
}
.ball--yellow {
  background: radial-gradient(farthest-corner at 150% 150%, #fff 5%, #d9b200);
}
.invisible {
  opacity: 0;
}
.flex-grow {
  flex-grow: 1;
}
.bold {
  font-weight: bold;
}
@-moz-keyframes score-update {
  0% {
    color: #a3ca9e;
  }
  35% {
    color: #a3ca9e;
  }
  100% {
    color: #503e3e;
  }
}
@-webkit-keyframes score-update {
  0% {
    color: #a3ca9e;
  }
  35% {
    color: #a3ca9e;
  }
  100% {
    color: #503e3e;
  }
}
@-o-keyframes score-update {
  0% {
    color: #a3ca9e;
  }
  35% {
    color: #a3ca9e;
  }
  100% {
    color: #503e3e;
  }
}
@keyframes score-update {
  0% {
    color: #a3ca9e;
  }
  35% {
    color: #a3ca9e;
  }
  100% {
    color: #503e3e;
  }
}
